<template>
  <div class="us">
    <!-- 右侧 介绍 -->
    <div class="us-info">
      <h2>关于我们</h2>
      <p>
        北京蓝色奥义科技有限公司成立于2023年02月28日，注册地位于北京市丰台区南四环西路188号十六区19号楼9层101内2951号
      </p>
      <p>
        经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；信息系统集成服务；计算机系统服务；软件开发；人工智能基础软件开发；人工智能应用软件开发；软件销售；专业设计服务；广告发布；广告设计、代理；计算机软硬件及辅助设备批发；计算机软硬件及辅助设备零售；数据处理服务；
      </p>
      <p>
        技术进出口；货物进出口；进出口代理。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：基础电信业务；互联网信息服务；第二类增值电信业务；网络文化经营；演出经纪；广播电视节目制作经营；出版物零售；出版物批发。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）（不得从事国家和本市产业政策禁止和限制类项目的经营活动。） 
      </p>
      <div class="us-btn" @click="goUs()">
        <span> MORE ></span>
        <div class="blink"></div>
      </div>
    </div>

    <!-- 左侧图片 -->
    <div class="us-img">
      <img src="/imgs/xz.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 关于我们跳转
    goUs() {
      this.$router.push("/ac2");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.us {
  width: 100%;
  height: 400px;
  margin: 50px auto;
  // background: #c60023;
  @include flex();
  // 左侧图片
  .us-img {
    width: 50%;
    height: 100%;
    text-align: center;

    img {
      width: 400px;
      height: 400px;
      // margin: 30px auto;
    }
  }
  // 右侧 信息
  .us-info {
    width: 50%;
    height: 100%;
    // background-color: pink;
    padding-top: 30px;
    box-sizing: border-box;
    h2 {
      font-size: 31px;
    }

    p {
      font-size: 14px;
      margin: 20px auto;
    }

    .us-btn {
      width: 150px;
      text-align: center;
      height: 40px;
      //   background: #eee;
      position: relative;
      cursor: pointer;
      &:hover span {
        color: #fff;
      }
      &:hover .blink {
        height: 100%;
      }
      span {
        color: turquoise;
        position: absolute;
        left: 33%;
        bottom: 11px;
        z-index: 10;
        transition: all 0.2s;
      }
      .blink {
        width: 100%;
        height: 3px;
        background-color: turquoise;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: all 0.3s;
      }
    }
  }
}
</style>
