<template>
  <div>
    <nav-header :href="this.href"></nav-header>
    <router-view></router-view>
    <nav-footer
      :email="this.email"
      :phone="this.phone"
      :name="this.name"
      :addr="this.addr"
      :icp="this.icp"
    ></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
export default {
  name: "home",
  components: {
    NavFooter,
    NavHeader,
  },
  mounted() {
    document.title = "北京蓝色奥义科技有限公司";
  },
  data() {
    return {
      href: 'index.html',
      email: "邮箱：745620443@qq.com",
      phone: "电话：13943093588",
      icp: "备案号：新ICP备2020001003号-1 ",
      name: "乌鲁木齐博智素能文化传播有限公司",
      addr:
        "地址：新疆乌鲁木齐市水磨沟区立井街198号丽景·名都公寓楼15层10号办公1506",
    };
  },
};
</script>
<style lang="" scoped></style>
