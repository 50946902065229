<template>
  <div class="nav-footer">
    <!-- 上层 -->
    <div class="f-s">
      <div class="container">
        <!-- logo -->
        <!-- <div class="f-logo"> -->
        <!-- <img src="/imgs/logo.png" alt="" /> -->
        <!-- </div> -->

        <!-- 二维码 -->
        <div class="f-ewm" style="">
          <!-- <img src="/imgs/xz.png" alt="" /> -->
          <p>北京蓝色奥义科技有限公司</p>
          <!-- <img src="/imgs/二维码/2.jpg" alt="" />
          <img src="/imgs/二维码/3.jpg" alt="" /> -->
        </div>
        <!-- 左侧信息 -->
        <div class="f-list">
          <ul>
            <!-- 公司 -->
            <li>公司名称：北京蓝色奥义科技有限公司</li>
            <!-- 电话 -->
            <li>公司电话：13911711822</li>
            <!-- <li>公司邮箱：1215272580@qq.com</li> -->
            <!-- 邮箱 -->
            <li>网站域名：blueultra.net</li>
            <!-- 地址 -->
            <li>
              公司地址：北京市丰台区南四环西路188号十六区19号楼9层101内2951号
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- ICP -->
    <div class="f-icp">
      <div class="container">
        <div class="icp">
          备案号:
          <a href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2023013623号-3</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-footer {
  width: 100%;
  height: 360px;
  // 客户 logo 层
  .f-ctm {
    background-color: #121212;
    width: 100%;
    height: 150px;
    .container {
      height: 100%;
      @include flex();
      img {
        width: 150px;
        height: 80px;
        // width: 100%;
        // height: 100%;
      }
    }
  }
  .f-s {
    width: 100%;
    height: 320px;
    background-color: #4e4e4e;
    .container {
      height: 100%;
      @include flex();
      padding: 0 40px;
      box-sizing: border-box;
      .f-logo {
        // border-right: 2px solid $colorZ;
        width: 200px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      // 左侧信息
      .f-list {
        ul {
          li {
            font-size: 14px;
            color: #fff;
            cursor: pointer;
            margin: 45px auto;
          }
        }
      }
      // 二维码
      .f-ewm {
        // width: 500px;
        // height: 100%;
        // background-color: #fff;
        // @include flex();
        // line-height: 320px;
        img {
          width: 150px;
          height: 150px;
          margin: 0 10px;
        }
        p {
          display: block;
          font-size: 16px;
          // font-weight: bold;
          cursor: pointer;
          color: #fff;
          margin-top: 10px;
          text-align: center;
        }
      }
      // 右侧提交列表
      .f-info {
        ul {
          li {
            margin: 20px auto;
            color: #fff;
            input {
              color: #fff;
              width: 300px;
              height: 30px;
              padding-left: 10px;
              background-color: #eee;
              box-sizing: border-box;
              border-style: none;
            }
          }
        }
        .f-btn {
          border-top-color: #351c75;
          border-right-color: #351c75;
          border-bottom-color: #351c75;
          border-left-color: #351c75;
          background-color: #351c75;
          background-image: none;
          background-repeat: no-repeat;
          background-position: 0 0;
          width: 95px;
          height: 30px;
          color: #fff;
          text-align: center;
          line-height: 30px;
          font-size: 14px;
          margin: 0 auto;
          cursor: pointer;
        }
      }
    }
  }
  //   icp 层
  .f-icp {
    width: 100%;
    height: 60px;
    background-color: rgb(41, 41, 41);
    .container {
      text-align: center;
      line-height: 60px;
      .icp {
        color: #fff;
        font-size: 13px;
        a {
          color: #fff;
          font-size: 13px;
        }
      }
    }
  }
}
</style>
