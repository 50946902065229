<template>
  <div class="ac1">
    <div class="container">
      <div class="banner">
        <img src="/imgs/01.jpg" alt="" />
      </div>

      <!-- 资讯区块2 -->
      <div class="a2">
        <!-- 右侧 -->
        <div class="a2-r">
          <!-- 标题 -->
          <div class="r-title">
            <h2>行业资讯</h2>
          </div>

          <!-- 循环项 -->
          <div
            class="r-item"
            v-for="(item, index) in this.mAc"
            :key="index"
            @click="goDays(item.methods, item.id)"
          >
            <!-- 右侧信息 -->
            <div class="item-info">
              <div class="blink"></div>
              <span><i class="iconfont icon-zuozhe"></i> {{ item.author }}</span>
              <span><i class="iconfont icon-time"></i>{{ item.time }}</span>
              <span style=""> <i class="iconfont icon-dianzan"></i> {{ item.pay }} </span>
              <!-- 标题 -->
              <h2>{{ item.title }}</h2>
              <!-- 简介 -->
              <p>
                {{ item.jj }}
              </p>
              <!-- <p style="color: #c60023; font-size: 14px">收费阅读：2元/篇</p> -->
            </div>

            <!-- 左侧图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      title="请付款阅读"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <h2>微信支付：2元</h2>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal.vue";
export default {
  name: "ac1",
  components: {
    Modal,
  },
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      myData: [],
      mAc: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init1();
    document.title = "北京蓝色奥义科技有限公司";
  },
  methods: {
    init1() {
      this.axios.get("/mock/info.json").then((res) => {
        const data = res.data;
        // 咨询区块1
        this.myData = data.slice(0, 20);
        this.ac1 = data.slice(0, 5);
        this.ac2 = data.slice(5, 10);
        this.mAc = data.slice(0, 20);
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      // alert("资讯每篇2元，请付费阅读");
      // this.$router.push({ path: "/pay" });
      this.showModal = true;
      // alert("请付费阅读");
      // this.showModal = true;
      // if (this.state == 0) {
      //   this.$router.push("/pay");
      //   // this.showModal = true;
      //   // console.log(this.showModal);
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("资讯每条2元，点击确定登录购买");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac1 {
  padding-top: 80px;
  box-sizing: border-box;
  .container {
    .banner {
      width: 100%;
      height: 154px;
      margin: 30px 0;
      img {
        width: 100%;
        height: 100%;
      }
    }

    // 资讯区块2
    .a2 {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      margin: 60px 0;

      // 右侧
      .a2-r {
        width: 100%;
        height: 100%;
        // background-color: pink;
        .r-title {
          width: 100%;
          height: 50px;
          border-left: 3px solid $colorZ;
          padding-left: 30px;
          box-sizing: border-box;
          border-bottom: 1px dashed #ccc;
          h2 {
            line-height: 50px;
            font-weight: 400;
            color: #333;
          }
        }
        // 循环项
        .r-item {
          width: 100%;
          height: 160px;
          // background: #c60023;
          margin: 20px 0;
          display: flex;
          align-items: center;
          border-bottom: 2px dashed #ccc;
          transition: all 0.2s;
          // border-top: 2px solid #fff;
          cursor: pointer;
          border-top-width: 1;
          &:hover {
            background-color: #eee;

            .item-info {
              h2 {
                color: #333;
              }
              .blink {
                width: 100%;
              }
            }
          }
          .item-img {
            width: 300px;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .item-info {
            margin-right: 30px;
            width: 70%;
            transition: all 0.4s;
            height: 100%;
            padding-top: 35px;
            box-sizing: border-box;
            position: relative;
            padding-left: 20px;
            .blink {
              transition: all 0.4s;
              width: 0px;
              height: 2px;
              background-color: #00bfb0;
              position: absolute;
              top: 0;
              left: 0;
            }
            span {
              font-size: 12px;
              margin-right: 15px;
              color: #888;
              i {
                font-weight: 400;
                margin-right: 5px;
                font-size: 14px;
              }
            }
            h2 {
              font-size: 16px;
              color: #333;
              margin: 10px auto;
              transition: all 0.2s;
            }
            p {
              font-size: 12px;
              color: #888;
              line-height: 30px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
</style>
